import styled from '@emotion/styled';
import Button from '@ingka/button';
import { space250, space50 } from '@ingka/variables';

export const EditDeleteButton = styled(Button)`
  min-height: ${space250};
  min-width: 7rem;
`;

export const ButtonWrapper = styled.span`
  display: flex;
  gap: ${space50};
  justify-content: flex-end;
`;
