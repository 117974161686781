import styled from '@emotion/styled';
import { colourNeutral2, colourTextAndIcon2 } from '@ingka/variables/colours-css';
import { FlexContainer } from '../FlexContainer';
import Text from '@ingka/text';

export const NoDataWrapper = styled(FlexContainer)`
  background-color: ${colourNeutral2};
  width: 100%;
  height: 100%;
`;

export const NoDataImage = styled.img`
  max-width: 8.5rem;
`;

export const NoDataContent = styled(FlexContainer)<{
  textAlign?: string;
  maxWidth?: string;
}>`
  max-width: ${(props) => props.maxWidth || '18.25rem'};
  text-align: ${(props) => props.textAlign || 'start'};
`;

export const NoDataTitle = styled(Text)`
  color: ${colourTextAndIcon2};
`;
