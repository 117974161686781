import React, { FC, SyntheticEvent } from 'react';
import * as Styled from './styles';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import pencil from '@ingka/ssr-icon/paths/pencil';
import { useTable } from '../index';

export type TableActionButtonsProps = {
  hideActions?: boolean;
  onDelete: () => void;
  isEditingAllowed: boolean;
  onEditClick: (e?: SyntheticEvent) => void;
  hideDelete?: boolean;
};

export const TableActionButtons: FC<TableActionButtonsProps> = ({ hideActions, onDelete, isEditingAllowed, onEditClick, hideDelete = false }) => {
  const { type } = useTable();
  return (
    <>
      {!hideActions && (
        <Styled.ButtonWrapper>
          {!hideDelete && (
            <Styled.EditDeleteButton
              type={'tertiary'}
              text={'Delete'}
              size={'small'}
              ssrIcon={trashCan}
              onClick={onDelete}
              disabled={!isEditingAllowed}
              data-testid={`delete-${type}-modal-button`}
            />
          )}
          <Styled.EditDeleteButton
            type={'secondary'}
            text={'Edit'}
            size={'small'}
            ssrIcon={pencil}
            data-testid={`edit-${type}-modal-button`}
            onClick={onEditClick}
            disabled={!isEditingAllowed}
          />
        </Styled.ButtonWrapper>
      )}
    </>
  );
};
