import React, { FC, useState } from 'react';
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import { Column as RuleTableColumn } from 'types';
import { DeleteRuleModal } from '../DeleteRuleModal';
import { RuleType } from '../index';
import { FloatingMenuWithPillButton, FloatingMenuWithPillMenuItem } from 'components/FloatingMenuWithPillButton';
import { RuleTableCells } from '../RuleTableCell';
import Table from 'components/Table';

type RuleProps = {
  rule: RuleType;
  onCheckboxClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
  isEditingAllowed: boolean;
  isSelected: boolean;
  columns: RuleTableColumn[];
  isGlobal: boolean;
  id: string;
  hideActions: boolean;
  hideActionsIcon: boolean;
  policy: string;
};

const shortenNumber = (number: number): string => {
  const suffixes = ['', 'k', 'M', 'B', 'T'];
  const suffixIndex = Math.floor(Math.log10(number) / 3);
  const shortNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
  return shortNumber + suffixes[suffixIndex];
};

export const RuleItem: FC<RuleProps> = ({
  rule,
  onCheckboxClick,
  onEditClick,
  onDeleteClick,
  isEditingAllowed,
  isSelected,
  columns,
  isGlobal,
  id,
  policy,
  hideActions,
  hideActionsIcon,
}) => {
  const { docRefId, name, invocations, targetURL } = rule;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const invocationsShortend = invocations >= 1000 ? shortenNumber(invocations) : invocations;

  const floatingMenuItems: FloatingMenuWithPillMenuItem[] = [
    {
      onClick: onEditClick,
      text: 'Edit',
      icon: pencil,
      dataTestId: 'edit-rule-modal-button',
    },
    {
      onClick: () => setIsDeleteModalOpen(true),
      text: 'Delete',
      icon: trashCan,
      dataTestId: 'delete-rule-modal-button',
    },
  ];

  return (
    <Table.TableRow
      hideActions={hideActions}
      id={docRefId}
      isDraggable={isGlobal}
      onCheckboxClick={onCheckboxClick}
      isSelected={isSelected}
      dataTestId={'rule-row'}
    >
      <RuleTableCells columns={columns} rule={rule} invocationsShortend={invocationsShortend} isGlobal={isGlobal} />
      {(!hideActionsIcon || !hideActions) && (
        <td>
          <Table.TableActionButtons
            hideActions={hideActions}
            onDelete={() => setIsDeleteModalOpen(true)}
            onEditClick={onEditClick}
            isEditingAllowed={isEditingAllowed}
          />
          {!hideActionsIcon && <FloatingMenuWithPillButton menuItems={floatingMenuItems} disabled={!isEditingAllowed} />}
          {isDeleteModalOpen && (
            <DeleteRuleModal
              isVisible={isDeleteModalOpen}
              onSubmit={() => {
                onDeleteClick();
                setIsDeleteModalOpen(false);
              }}
              onCancel={() => setIsDeleteModalOpen(false)}
              selectedRules={[docRefId]}
              ruleName={name}
              ruleTargetURL={targetURL}
              policy={policy}
            />
          )}
        </td>
      )}
    </Table.TableRow>
  );
};
