import React, { createContext, FC, ReactNode, useContext } from 'react';
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { FlexContainer } from '../FlexContainer';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useComponentOffsetTop } from '../../hooks/useComponentOffsetTop';
import * as Styled from './styles';
import { Loader } from '../Loader';
import { NoData } from '../NoData';
import { TableColumnHeader, TableColumnHeaderProps } from './TableColumnHeader';
import { TableRow, TableRowProps } from './TableRow';
import { TableActionButtons, TableActionButtonsProps } from './TableActionButtons';
import { TableBody, TableBodyProps } from './TableBody';
import { TableThumbnailRow, TableThumbnailRowProps } from './TableThumbnailViewRow';
import { TableHeader, TableHeaderProps } from './TableHeader';

interface TableComposition {
  TableColumnHeader: FC<TableColumnHeaderProps>;
  TableHeader: FC<TableHeaderProps>;
  TableRow: FC<TableRowProps>;
  TableActionButtons: FC<TableActionButtonsProps>;
  TableBody: FC<TableBodyProps>;
  TableThumbnailRow: FC<TableThumbnailRowProps>;
}

interface TableProps {
  children: ReactNode;
  showNoData: boolean;
  handleDragEnd?: (event: DragEndEvent) => void;
  hideActions?: boolean;
  customTablePropsWhenGlobal?: any;
  isLoading: boolean;
  type: 'rule' | 'metadata';
}

interface TableContextType {
  isLoading: boolean;
  showNoData: boolean;
  type: 'rule' | 'metadata';
}

const TableContext = createContext<undefined | TableContextType>(undefined);

export const useTable = () => {
  const context = useContext(TableContext);
  if (context === undefined) {
    throw new Error('useTable must be used within a TableProvider');
  }
  return context;
};

const Table: FC<TableProps> & TableComposition = ({
  children,
  showNoData,
  handleDragEnd,
  hideActions,
  customTablePropsWhenGlobal,
  isLoading,
  type,
}) => {
  const { componentRef: tableRef, componentOffsetTop: offsetTop } = useComponentOffsetTop();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  if (isLoading) return <Loader minHeight={'70vh'} />;

  return (
    <TableContext value={{ isLoading, showNoData, type }}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
        <FlexContainer ref={tableRef} direction={'column'}>
          <Styled.Table
            offsettop={!hideActions ? offsetTop : undefined}
            fullWidth
            {...customTablePropsWhenGlobal}
            height={showNoData ? 'fit-content' : undefined}
          >
            {children}
          </Styled.Table>
          {showNoData && (
            <Styled.NoDataWrapper>
              <NoData
                imageFlexPosition={'row'}
                text={'Try a different search term or adjust your filters.'}
                heading={'No results found.'}
                textAlign={'center'}
                contentAlign={'center'}
                id={`${type}-no-data`}
                contentMaxWidth={'fit-content'}
              />
            </Styled.NoDataWrapper>
          )}
        </FlexContainer>
      </DndContext>
    </TableContext>
  );
};

Table.TableHeader = TableHeader;
Table.TableColumnHeader = TableColumnHeader;
Table.TableRow = TableRow;
Table.TableActionButtons = TableActionButtons;
Table.TableBody = TableBody;
Table.TableThumbnailRow = TableThumbnailRow;

export default Table;
