import React, { FC, ReactNode } from 'react';
import { TableHeader as TableHeaderIngka } from '@ingka/table';
import { useTable } from '../index';

export type TableHeaderProps = { children: ReactNode };

export const TableHeader: FC<TableHeaderProps> = ({ children }) => {
  const { isLoading } = useTable();
  return (
    <>
      {!isLoading && (
        <TableHeaderIngka sticky>
          <tr>{children}</tr>
        </TableHeaderIngka>
      )}
    </>
  );
};
