import styled from '@emotion/styled';
import IngkaTable from '@ingka/table';
import { space125, space50 } from '@ingka/variables';
import { colourNeutral2, colourStaticBlack } from '@ingka/variables/colours-css';

export const Table = styled(IngkaTable)<{
  targetElementIndex?: number;
  offsettop?: number;
  height?: string;
}>`
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  height: ${({ offsettop, height }) => height || `calc(100vh - ${offsettop}px - ${space125} - ${space50})`};

  th {
    padding-left: ${space50};
    padding-right: ${space50};

    :nth-of-type(${(props) => props.targetElementIndex || 2}):nth-of-type(${(props) => (props.targetElementIndex || 2) + 1}) {
      width: 100%;
    }
  }

  .text {
    color: ${colourStaticBlack};
  }

  .table,
  tbody,
  td {
    padding: ${space50} !important;
    vertical-align: middle;
    width: fit-content;
    height: fit-content;
    word-break: break-all;

    :nth-of-type(${(props) => props.targetElementIndex || 2}):nth-of-type(${(props) => (props.targetElementIndex || 2) + 1}) {
      width: 25%;
    }

    tr {
      :nth-of-type(odd) {
        background-color: ${colourNeutral2};
      }

      :nth-of-type(even) {
        background-color: transparent;
      }
    }
  }
`;

export const NoDataWrapper = styled.div`
  height: 70vh;
`;
