import { MetadataItem } from '../MetaDataItem';
import { Provider as EditorProvider } from 'hooks/contexts/EditorContext';
import { EditSheet } from '../../../MetaData/EditSheet';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { CurrentVersionQuery, FilterInput, MetaDataEntryInput, useEditItemMutation } from '__generated__/types';
import { toMetaData } from 'opt-util/toGenericMetadata';
import { useLoader } from 'hooks/useLoader';
import { Context as MetadataContext } from 'hooks/contexts/MetaDataContext';
import { useLocation } from 'react-router-dom';
import { Column as MetaDataTableColumns } from 'types';

interface MetadataTableBodyProps {
  isEditable: boolean;
  data: NonNullable<CurrentVersionQuery['currentVersion']>;
  displayListOrSquareView: number;
  refetchCallback: () => void;
  filter: FilterInput;
  columns: MetaDataTableColumns[];
}

export const MetadataTableBody: FC<MetadataTableBodyProps> = ({ displayListOrSquareView, data, filter, refetchCallback, isEditable, columns }) => {
  const {
    state: { activeItemId: itemId },
  } = useContext(MetadataContext);
  const entries = data?.entries?.entries;
  const location = useLocation();
  const [, , country, language, type] = location.pathname.split('/');
  const [, setLoading] = useLoader();
  const [editItem] = useEditItemMutation();

  const versionDetails = {
    type: data?.type,
    urlSlugSyncEnabled: !!data?.urlSlugSyncEnabled,
    versionId: data?._id,
    market: { country: data?.country, language: data?.language },
  };

  const editorInitialState = useMemo(() => {
    const currentlyEditedEntry = entries?.find((entry) => entry.id === itemId);
    if (!currentlyEditedEntry) return;
    const descriptionIsSynced = currentlyEditedEntry.description === currentlyEditedEntry.ogDescription;
    const isOptimized = !!currentlyEditedEntry.isOptimized;
    const needsReview = !!currentlyEditedEntry.needsReview;
    return {
      formData: {
        ...currentlyEditedEntry,
        descriptionIsSynced,
        isOptimized,
        needsReview,
      },
      hasErrors: false,
      errors: {},
      versionDetails,
      isOpen: true,
      itemId,
    };
  }, [entries, itemId]);

  const editItemCallback = useCallback(
    async (entry: MetaDataEntryInput) => {
      try {
        const item = toMetaData(entry);
        await editItem({
          variables: {
            country,
            language,
            type,
            entry: { ...item, pageTitle: item.pageTitle || '' },
            filter,
          },
        });
        setLoading(false);
        return refetchCallback();
      } catch (e) {
        console.log(e);
      }
    },
    [country, language, type, filter, refetchCallback],
  );

  return (
    <>
      {entries?.length > 0 &&
        entries?.map((entry) => {
          return (
            <MetadataItem
              key={entry.id}
              data={entry}
              isEditable={isEditable}
              type={data.type}
              author={entry.author ?? 'unknown'}
              filter={filter}
              onDelete={refetchCallback}
              displayListOrSquareView={displayListOrSquareView}
              columns={columns}
            />
          );
        })}
      {!!editorInitialState && data && (
        <EditorProvider injectedState={editorInitialState}>
          <EditSheet editItem={editItemCallback} />
        </EditorProvider>
      )}
    </>
  );
};
