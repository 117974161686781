import React from 'react';
import Text from '@ingka/text';
import * as Styled from './styles';

interface MetaDataStrippedTableItemProps {
  entryId: string;
  searchClicks: number | undefined;
  searchCtr: string | undefined;
  searchImpressions: number | undefined;
  avgPosition: number | undefined;
}

export const MetaDataStrippedTableItem: React.FC<MetaDataStrippedTableItemProps> = ({
  entryId,
  searchClicks,
  searchCtr,
  searchImpressions,
  avgPosition,
}) => {
  const seoMetric = [
    { title: 'Clicks', value: searchClicks },
    { title: 'CTR', value: searchCtr },
    { title: 'Impressions', value: searchImpressions },
    { title: 'Average', value: avgPosition },
  ];
  return (
    <Styled.MetaDataCollapsedWrapper data-testid={`${entryId}-expanded-row`}>
      <Styled.MetaDataCollapsedWrapperCell colSpan={10}>
        <Styled.MetaDataCollapsedHeader>
          <Text>SEO metrics</Text>
        </Styled.MetaDataCollapsedHeader>

        <Styled.MetaDataCollapsedGroupedCell>
          {seoMetric.map(({ title, value }, index) => (
            <div key={`${index}-${title}`}>
              <Text>{title}</Text>
              <Text>{value}</Text>
            </div>
          ))}
        </Styled.MetaDataCollapsedGroupedCell>
      </Styled.MetaDataCollapsedWrapperCell>
    </Styled.MetaDataCollapsedWrapper>
  );
};
