import styled from '@emotion/styled';
import { fontWeightBold } from '@ingka/variables/design-tokens';

export const MetaDataCollapsedRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > div {
    width: 50%;
  }
`;

export const MetaDataCollapsedGroupedCell = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 25%;

    div + div {
      color: #003e72;
      font-weight: ${fontWeightBold};
    }
  }
`;

export const MetaDataCollapsedHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 47px;
  max-height: 47px;
  gap: 8px;
`;

export const MetaDataCollapsedWrapper = styled.tr`
  background-color: #e6ecf1 !important;
  padding: 0 8px 8px 8px;
`;

export const MetaDataCollapsedWrapperCell = styled.td`
  padding-left: 8px !important;
`;
