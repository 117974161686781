import React, { FC, useContext, useEffect, useState } from 'react';
import { $convertFromMarkdownString, $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getRoot, EditorState } from 'lexical';
import { Context as EditorContext } from 'hooks/contexts/EditorContext';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import { RichText } from './plugins/RichTextPlugin';
import { getEditorConfig } from './editorConfig';
import './styles.css';

type RichEditorProps = {
  namespace: string;
  value: string;
  editorLabel?: string;
  hideToolbar?: boolean;
  outputFormat?: 'markdown' | 'raw';
  compact?: boolean;
  id?: string;
  showBlockOptions?: boolean;
};
export const RichEditor: FC<RichEditorProps> = ({
  id,
  namespace,
  value,
  hideToolbar = false,
  editorLabel,
  compact,
  outputFormat = 'markdown',
  showBlockOptions = true,
}) => {
  const [initialConfig, setConfig] = useState<InitialConfigType | null>();
  const editorState = () => $convertFromMarkdownString(value, TRANSFORMERS);
  const editConfig = getEditorConfig(namespace);
  const { setField } = useContext(EditorContext);

  const onEditorStateChange = (editorState: EditorState) => {
    editorState.read(() => {
      const rawContent = $getRoot().getTextContent();
      const markdown = $convertToMarkdownString(TRANSFORMERS);
      outputFormat !== 'markdown' ? setField(id, rawContent) : setField(id, markdown);
    });
  };
  useEffect(() => {
    setConfig({ ...editConfig, editorState });
  }, []);

  if (!initialConfig) {
    return null;
  }
  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichText hideToolbar={hideToolbar} editorLabel={editorLabel} compact={compact} showBlockOptions={showBlockOptions} />
      <HistoryPlugin />
      <ListPlugin />
      <LinkPlugin />
      <AutoLinkPlugin />
      <ListMaxIndentLevelPlugin maxDepth={7} />
      <OnChangePlugin onChange={onEditorStateChange} />
      <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
    </LexicalComposer>
  );
};
