import { ColumnHeader } from 'features/Rules';
import React, { SVGProps } from 'react';
import { Column as TableColumn } from 'types';
import ascIcon from '@ingka/ssr-icon/paths/sort-ascending';
import descIcon from '@ingka/ssr-icon/paths/sort-descending';
import * as Styled from './styles';
import { space50 } from '@ingka/variables';
import Text from '@ingka/text';
import SSRIcon from '@ingka/ssr-icon';
import { useTable } from '../index';
import { SortFieldRule, useSort } from 'hooks/contexts/SortContext';
import { Tooltip } from '../../Tooltip';
import { tooltipText, TooltipTextKeyField } from 'features/Rules/tooltip';

export type TableColumnHeaderProps = {
  columnHeader: ColumnHeader;
  hideSortAction?: boolean;
};
export const TableColumnHeader: React.FC<TableColumnHeaderProps> = ({ columnHeader, hideSortAction }) => {
  const { type } = useTable();
  const { sort, setSort } = useSort();

  const onSetSort = (field: SortFieldRule) => {
    if (sort.field === field && sort.direction === 'asc') {
      return setSort((prev) => ({ ...prev, direction: 'desc' }));
    }

    if (sort.field === field && sort.direction === 'desc') {
      return setSort({ field: null, direction: null });
    }
    return setSort({
      field: field,
      direction: 'asc',
    });
  };

  const isColumnHeaderContent = (columnHeader: ColumnHeader): columnHeader is TableColumn => {
    return columnHeader !== null && ('label' in columnHeader || 'field' in columnHeader);
  };
  if (isColumnHeaderContent(columnHeader)) {
    const isSortedColumn = sort.field === columnHeader?.field;
    const sortIcon = isSortedColumn ? (sort.direction === 'asc' ? ascIcon : descIcon) : ascIcon;
    const hasTooltip = columnHeader?.field === 'targetStatus' || columnHeader?.field === 'invocations';
    return (
      <Styled.TableHeading
        aria-label={`col-${columnHeader.field}`}
        onClick={() => columnHeader?.field && type === 'rule' && !hideSortAction && onSetSort(columnHeader.field as SortFieldRule)}
        width={columnHeader.width}
        cursor={type === 'rule' && !hideSortAction && 'pointer'}
      >
        <Styled.TableColumnHeading gap={space50}>
          <Text bodySize="s">{columnHeader.label}</Text>
          {hasTooltip && <Tooltip>{tooltipText[columnHeader.field as TooltipTextKeyField]}</Tooltip>}
          {columnHeader?.field && !hideSortAction && (
            <TableColumnIcon isSortedColumn={isSortedColumn} paths={sortIcon} columnField={columnHeader.field} />
          )}
        </Styled.TableColumnHeading>
      </Styled.TableHeading>
    );
  }

  return (
    <Styled.TableHeading>
      <Styled.TableColumnHeading>{columnHeader}</Styled.TableColumnHeading>
    </Styled.TableHeading>
  );
};

export const TableColumnIcon: React.FC<{
  isSortedColumn: boolean;
  paths: (prefix?: string | undefined) => SVGProps<SVGElement>[];
  columnField: string;
}> = ({ isSortedColumn, paths, columnField }) => (
  <Styled.SortIcon isSortedColumn={isSortedColumn}>
    <SSRIcon id={'sort-icon'} paths={paths} data-testid={`sort-icon-${columnField}`} />
  </Styled.SortIcon>
);
