import styled from '@emotion/styled';
import { space100 } from '@ingka/variables';

export const TableThumbnailRow = styled.tr`
  display: flex;
  flex-direction: row;
  margin: ${space100} 0;
  padding: ${space100};
  justify-content: space-between;

  & > *:nth-of-type(1) {
    flex: 1 0 70%;
  }

  .prop {
    display: flex;
    flex-direction: row;

    .label {
      font-weight: bold;
      flex: 0 0 150px;
    }

    .value {
      overflow-wrap: break-word;
      word-break: break-all;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
