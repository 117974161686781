import React, { SyntheticEvent, useContext, useState } from 'react';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down';
import chevronUp from '@ingka/ssr-icon/paths/chevron-up';
import { Context as MetaDataContext } from 'hooks/contexts/MetaDataContext';
import { Column as MetaDataTableColumn } from 'types';
import { MetaDataEntry } from '__generated__/types';
import { MetaDataCell } from '../MetaDataCell';
import { isPublicera } from 'opt-util/helpers';
import Table from 'components/Table';
import { MetaDataStrippedTableItem } from '../MetaDataStrippedTableItem';

import * as Styled from './styles';

interface MetaDataTableCollapsibleRowProps {
  isPlpPage: boolean;
  entry: MetaDataEntry;
  onMetadataDelete: () => void;
  visibleColumns: MetaDataTableColumn[];
  type: string;
  isEditable: boolean;
}

export const MetaDataTableCollapsibleRow: React.FC<MetaDataTableCollapsibleRowProps> = ({
  isPlpPage,
  entry,
  onMetadataDelete,
  visibleColumns,
  type,
  isEditable,
}) => {
  const [expandedRowId, setExpandedRowId] = useState<string[]>([]);
  const [toggleChevron, setToggleChevron] = useState<boolean>(false);
  const { setIsEditorOpen } = useContext(MetaDataContext);
  const onRowToggle = (id: string) => {
    setToggleChevron(!toggleChevron);
    if (expandedRowId.includes(id)) {
      setExpandedRowId(expandedRowId.filter((rowId) => rowId !== id));
    } else {
      setExpandedRowId([...expandedRowId, id]);
    }
  };

  const onMetadataEditClick = (e: SyntheticEvent, entryId: string) => {
    e.stopPropagation();
    setIsEditorOpen(true, entryId);
  };

  return (
    <>
      <Table.TableRow dataTestId={`${entry.id}-row`} key={`${entry.id}-row`} onClick={() => onRowToggle(entry.id)} clickable={isPlpPage}>
        <td>{isPlpPage && <Styled.ChevronBtn iconOnly ssrIcon={expandedRowId.includes(entry.id) ? chevronUp : chevronDown} size={'small'} />}</td>
        {visibleColumns.map((column, index) => (
          <MetaDataCell key={index} entry={entry} type={type} column={column} />
        ))}
        <td>
          <Table.TableActionButtons
            hideDelete={isPublicera(type) && type === 'filter'}
            onDelete={onMetadataDelete}
            onEditClick={(e?: SyntheticEvent) => e && onMetadataEditClick(e, entry.id)}
            isEditingAllowed={isEditable}
          />
        </td>
      </Table.TableRow>
      {expandedRowId.includes(entry.id) && isPlpPage && (
        <MetaDataStrippedTableItem
          entryId={entry.id}
          searchClicks={entry.searchConsoleData?.search_clicks}
          searchCtr={entry.searchConsoleData?.search_ctr}
          searchImpressions={entry.searchConsoleData?.search_impressions}
          avgPosition={entry.searchConsoleData?.avg_position}
        />
      )}
    </>
  );
};
