import styled from '@emotion/styled';
import { space125, space25, space50 } from '@ingka/variables';

export const ContainerWrapper = styled.div<{ padding?: string }>`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: ${({ padding }) => padding || space125};
  position: relative;

  .tabs__tab {
    padding: 0 ${space25} ${space50} ${space25};
  }
`;
