import styled from '@emotion/styled';
import React from 'react';

interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  direction?: 'row' | 'column';
  justify?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'space-evenly';
  align?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end' | 'stretch';
  grow?: 1 | 0;
  margin?: number | string;
  flexWrap?: 'wrap' | 'nowrap';
  id?: string;
  gap?: number | string;
  pt?: number | string;
  pl?: number | string;
  pr?: number | string;
  mt?: number | string;
  mr?: number | string;
  mb?: number | string;
  p?: number | string;
  flex?: 1 | 0;
}

const transformByType = (value: number | string | undefined) => {
  if (value === undefined) return '0em';
  if (typeof value === 'number') {
    return `${value}em`;
  } else {
    return value;
  }
};

export const FlexContainer = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'stretch'};
  flex-grow: ${(props) => props.grow || 0};
  margin: ${(props) => transformByType(props.margin)};
  margin-top: ${(props) => transformByType(props.mt)};
  margin-right: ${(props) => transformByType(props.mr)};
  margin-bottom: ${(props) => transformByType(props.mb)};
  gap: ${(props) => transformByType(props.gap)};
  padding-top: ${(props) => transformByType(props.pt)};
  padding-left: ${(props) => transformByType(props.pl)};
  padding-right: ${(props) => transformByType(props.pr)};
  padding: ${(props) => transformByType(props.p)};
  flex-wrap: ${(props) => props.flexWrap || 'nowrap'};
`;
