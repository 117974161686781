import styled from '@emotion/styled';
import { FlexContainer } from '../../FlexContainer';
import { space200 } from '@ingka/variables';
import { colourTextAndIcon4 } from '@ingka/variables/colours-css';

export const TableHeading = styled('th')<{ width?: number; cursor?: string }>`
  width: ${({ width }) => `${width}%`};

  :nth-of-type(n + 3):not(:nth-last-of-type(-n + 2)) {
    cursor: ${({ cursor }) => cursor || 'default'};
  }

  div {
    .text {
      white-space: nowrap;
    }
  }
`;

export const TableColumnHeading = styled(FlexContainer)`
  height: ${space200};
  display: flex;
  align-items: center;

  button {
    width: 22px;
    height: 22px;
  }

  &:hover {
    #sort-icon {
      visibility: visible;
      color: ${colourTextAndIcon4};
    }
  }
`;

export const SortIcon = styled.span<{ isSortedColumn: boolean }>`
  visibility: ${({ isSortedColumn }) => (isSortedColumn ? 'visible' : 'hidden')};
`;
