import React, { FC, ReactNode } from 'react';
import { TableBody as TableBodyIngka } from '@ingka/table';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

export type TableBodyProps = {
  children: ReactNode;
  sortIds?: string[];
};

export const TableBody: FC<TableBodyProps> = ({ children, sortIds }) => {
  return (
    <TableBodyIngka striped>
      <SortableContext items={sortIds ?? []} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </TableBodyIngka>
  );
};
