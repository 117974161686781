import { request } from 'gaxios';

const optimeraService = '/api/optimera';

export const checkApiRequest = async (token: string): Promise<void> => {
  try {
    const response = await request({
      method: 'GET',
      url: `${optimeraService}/locales/get-my-locales`,
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(`API request successful with status ${response.status}:`, response.data);
  } catch (error) {
    console.error('Error checking API request:', error);
  }
};
