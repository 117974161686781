import React from 'react';
import LocaleHeader from 'components/LocaleHeader';
import { Column as MetaDataTableColumn, Column as MetaDataTableColumns } from 'types';
import { CurrentVersionQuery, FilterInput } from '__generated__/types';
import { MetadataStats } from '../MetaDataStats';
import { isPublicera } from 'opt-util/helpers';
import Table from 'components/Table';
import { MetadataTableBody } from '../MetadataTableBody';

type ColumnHeader = MetaDataTableColumn | null;

interface MetaDataEntryTableDataProps {
  isEditable: boolean;
  data?: NonNullable<CurrentVersionQuery['currentVersion']> | null;
  displayListOrSquareView: number;
  refetchCallback: () => void;
  filter: FilterInput;
  columns: MetaDataTableColumns[];
  isLoading: boolean;
}

export const MetaDataEntryTableData: React.FC<MetaDataEntryTableDataProps> = ({
  isEditable,
  data,
  refetchCallback,
  filter,
  displayListOrSquareView,
  columns,
  isLoading,
}) => {
  const entries = data?.entries?.entries;
  const isPubliceraPage = isPublicera(data?.type);
  const noData = !isLoading && !entries?.length;
  const visibleColumns = columns.filter((column) => column.visible && column.label !== 'Keywords');
  const columnHeaders: ColumnHeader[] = [null, ...visibleColumns, null];
  return (
    <>
      {!isPubliceraPage && !isLoading && (
        <div>
          <LocaleHeader country={data?.country || ''} language={data?.language || ''} />
          <MetadataStats totalItems={data?.size || 0} optimizedItems={data?.optimizedItems || 0} />
        </div>
      )}
      <Table showNoData={noData} isLoading={isLoading} type={'metadata'}>
        <>
          {displayListOrSquareView === 0 && (
            <Table.TableHeader>
              {columnHeaders.map((header, index) => (
                <Table.TableColumnHeader key={index} columnHeader={header} />
              ))}
            </Table.TableHeader>
          )}
          <Table.TableBody>
            {!!entries && (
              <MetadataTableBody
                refetchCallback={refetchCallback}
                filter={filter}
                data={data}
                isEditable={isEditable}
                displayListOrSquareView={displayListOrSquareView}
                columns={columns}
              />
            )}
          </Table.TableBody>
        </>
      </Table>
    </>
  );
};
