import React, { useContext, useState } from 'react';
import Button from '@ingka/button';
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import { FlexContainer } from 'components/FlexContainer';
import { Context as MetadataContext } from 'hooks/contexts/MetaDataContext';
import { FilterInput, MetaDataEntry } from '__generated__/types';
import { View } from '../View';
import { isPublicera } from 'opt-util/helpers';
import { MetaDataTableCollapsibleRow } from '../MetaDataTableCollapsibleRow';
import { DeleteEntryModal } from '../DeleteEntryModal';
import { Column as MetaDataTableColumns } from 'types';
import Table from 'components/Table';

export interface MetadataItemProps {
  data: MetaDataEntry;
  isEditable: boolean;
  type: string;
  author: string;
  filter: FilterInput;
  onDelete: () => void;
  displayListOrSquareView: number;
  columns: MetaDataTableColumns[];
}

export const MetadataItem: React.FC<MetadataItemProps> = ({ data, isEditable, type, author, filter, onDelete, columns, displayListOrSquareView }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setIsEditorOpen } = useContext(MetadataContext);
  const isPubliceraPage = isPublicera(type);
  const isPlpPage = type === 'PLP';
  const visibleColumns = columns.filter((column) => column.visible && column.label !== 'Keywords');
  const hideDelete = isPubliceraPage && type === 'filter';
  return (
    <>
      <DeleteEntryModal
        entryId={data.id}
        pageTitle={data.pageTitle || ''}
        isVisible={modalOpen}
        onCancel={() => setModalOpen(false)}
        filter={filter}
        onDelete={onDelete}
      />
      {displayListOrSquareView === 0 ? (
        <MetaDataTableCollapsibleRow
          key={`${data.id}-row`}
          isPlpPage={isPlpPage}
          entry={data}
          visibleColumns={visibleColumns}
          type={type}
          isEditable={isEditable}
          onMetadataDelete={() => setModalOpen(true)}
        />
      ) : (
        <Table.TableThumbnailRow dataTestId={`metadata-row-${data.id}`}>
          <View data={data} type={type} author={author} />
          {isEditable && (
            <FlexContainer direction={'row'} align={'flex-start'}>
              <Button
                text={'Edit'}
                ssrIcon={pencil}
                type={'tertiary'}
                iconOnly={true}
                iconPosition={'leading'}
                onClick={() => setIsEditorOpen(true, data.id)}
                data-cy={'edit-button'}
              />
              {!hideDelete && (
                <>
                  <Button
                    text={'Delete'}
                    ssrIcon={trashCan}
                    type={'tertiary'}
                    iconOnly={true}
                    iconPosition={'leading'}
                    onClick={() => setModalOpen(true)}
                    data-cy={'delete-button'}
                    disabled={!data.isOptimized}
                  />
                </>
              )}
            </FlexContainer>
          )}
        </Table.TableThumbnailRow>
      )}
    </>
  );
};
