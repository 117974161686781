import * as Styled from './styles';
import { SearchField } from '../Search';
import { Pagination } from './Pagination';
import React, { BaseSyntheticEvent } from 'react';
import { EntryButtons } from './EntryButtons';
import { FilterAndSortModal } from './FilterAndSortModal';
import { ColumnsAndToggle } from './ColumnsAndToggle';
import { CheckboxFields } from '../CheckboxGroup';
import { SortInput } from '../../__generated__/types';
import { FilterContextInputs, FilterContextInputsKeyWithoutPage, useFilter } from 'hooks/contexts/FilterContext';
import { ActiveFiltersRow } from './ActiveFiltersRow';
import { FlexContainer } from 'components/FlexContainer';
import { CatalogReference } from '../../types';
import { isPublicera } from '../../opt-util/helpers';
import { space75 } from '@ingka/variables';

export type PageWithControlName = 'metadata' | 'rules';

type ColumnsAndToggleProps = {
  activeIndexListOrSquareView?: number;
  onToggleListOrSquareView?: (event: BaseSyntheticEvent, index: number) => void;
  defaultCheckBoxes: CheckboxFields[];
  defaultColumns: CheckboxFields[];
  onColumnsApply: (visibleColumns: string[]) => void;
};

type SearchFieldProps = {
  setSearchValue: (value: string) => void;
  debouncedValue?: number;
  searchPlaceholder?: string;
  searchFieldId: string;
  searchFieldTestId?: string;
};

type FilterAndSortProps = {
  filterType?: string;
  defaultSort: SortInput;
  defaultFilter: FilterContextInputs;
  sort: SortInput;
  onApplyFilter: (filter: FilterContextInputs, sort?: SortInput) => void;
  clearFilter: () => void;
  clearSort: () => void;
  deselectFilter: (key: FilterContextInputsKeyWithoutPage) => void;
};

export type EntryButtonsProps = {
  downloadFile?: () => void;
  uploadFile?: () => void;
  deleteClick?: () => void;
  totalRows?: number;
  isEditingAllowed?: boolean;
};

type PaginationProps = {
  totalPages?: number;
  itemsPerPage: number;
  totalItems: number;
  updateItemsPerPage: (value: number) => void;
};

type TableControlProps = {
  pageName: PageWithControlName;
  isMultiplePolicy?: boolean;
  clearSelectedRows?: () => void;
  clearSelectedRowsWithPolicy?: () => void;
  selectedRows?: string[];
  selectedRowsWithPolicy?: { policy: string; docRefId: string }[];
  categories?: CatalogReference[] | null;
} & PaginationProps &
  EntryButtonsProps &
  FilterAndSortProps &
  ColumnsAndToggleProps &
  SearchFieldProps;

export const Control: React.FC<TableControlProps> = ({
  clearSelectedRows,
  isMultiplePolicy,
  clearSelectedRowsWithPolicy,
  defaultCheckBoxes,
  selectedRows,
  downloadFile,
  uploadFile,
  selectedRowsWithPolicy,
  deleteClick,
  totalItems,
  itemsPerPage,
  updateItemsPerPage,
  searchFieldId,
  searchFieldTestId,
  searchPlaceholder,
  debouncedValue,
  setSearchValue,
  defaultSort,
  sort,
  onApplyFilter,
  clearSort,
  clearFilter,
  deselectFilter,
  defaultFilter,
  pageName,
  activeIndexListOrSquareView,
  onToggleListOrSquareView,
  onColumnsApply,
  defaultColumns,
  filterType,
  totalPages,
  categories,
  isEditingAllowed,
}) => {
  const onClearRows = () => {
    isMultiplePolicy ? clearSelectedRowsWithPolicy && clearSelectedRowsWithPolicy() : clearSelectedRows && clearSelectedRows();
  };
  const { setFilter } = useFilter();
  const isPubliceraPage = isPublicera(filterType);
  return (
    <FlexContainer direction={'column'} pt={space75} pl={space75} pr={space75}>
      <Styled.SearchWrapper>
        <SearchField
          id={searchFieldId}
          searchFieldTextId={searchFieldTestId}
          placeholder={searchPlaceholder || 'Search'}
          debouncedValue={debouncedValue}
          type="text"
          onLetterSearch={(value) => {
            setSearchValue(value);
            onClearRows();
          }}
        />

        {!isPubliceraPage && (
          <FilterAndSortModal
            defaultFilter={defaultFilter}
            defaultSort={defaultSort}
            sort={sort}
            onApply={onApplyFilter}
            type={filterType || ''}
            pageName={pageName}
            categories={categories}
          />
        )}
        <FlexContainer>
          <ColumnsAndToggle
            activeIndexListOrSquareView={activeIndexListOrSquareView}
            onToggleListOrSquareView={onToggleListOrSquareView}
            defaultCheckBoxes={defaultCheckBoxes}
            onColumnsApply={onColumnsApply}
            pageName={pageName}
            defaultColumns={defaultColumns}
          />
        </FlexContainer>
      </Styled.SearchWrapper>

      <Styled.FilteringWrapper>
        <EntryButtons
          downloadFile={downloadFile}
          uploadFile={uploadFile}
          deleteClick={deleteClick}
          selectedRowsWithPolicy={selectedRowsWithPolicy}
          selectedRows={selectedRows}
          totalRows={totalItems}
          pageName={pageName}
          isMultiplePolicy={isMultiplePolicy}
          filterType={filterType}
          isEditingAllowed={isEditingAllowed}
        />
        <Pagination
          totalPages={totalPages}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={(page) => {
            setFilter((prev) => ({ ...prev, page }));
            onClearRows();
          }}
          onItemsPerPageChange={(e) => {
            updateItemsPerPage(Number(e.target.value));
            onClearRows();
          }}
        />
      </Styled.FilteringWrapper>
      <ActiveFiltersRow sort={sort} defaultSort={defaultSort} clearFilter={clearFilter} clearSort={clearSort} deselectFilter={deselectFilter} />
    </FlexContainer>
  );
};
