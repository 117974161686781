import styled from '@emotion/styled';
import {
  colourNeutral7,
  colourSemanticCaution,
  colourSemanticInformative,
  colourSemanticNegative,
  colourSemanticPositive,
} from '@ingka/variables/colours-css';

import { fontSize100, fontSize800, radiusS, space100, space25, space300, space75 } from '@ingka/variables';
import Tooltip from '@ingka/tooltip';

export const StatusOverviewBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${space300} ${space75} ${space75} ${space75};
  flex-basis: 25%;
  box-sizing: border-box;
  color: ${colourNeutral7};
  text-align: center;
  border-radius: ${radiusS};

  p {
    font-size: ${fontSize100};
  }
`;

const DashboardBoxHeaderColours = {
  negative: colourSemanticNegative,
  cautious: colourSemanticCaution,
  positive: colourSemanticPositive,
  informative: colourSemanticInformative,
};

type DashboardBoxHeaderProps = { type: keyof typeof DashboardBoxHeaderColours };

export const DashboardBoxHeader = styled.h1<DashboardBoxHeaderProps>`
  display: flex;
  align-items: center;
  gap: ${space100};
  color: ${({ type }: DashboardBoxHeaderProps) => {
    return DashboardBoxHeaderColours[type];
  }};
  height: ${space300};
  font-size: ${fontSize800};
`;

export const StatusOverviewText = styled.p`
  display: flex;
  align-items: center;
  gap: ${space100};
  font-weight: bold;
  margin-top: ${space25};
  margin-bottom: ${space25};
`;

export const TooltipWithLessWidth = styled(Tooltip)`
  & > .tooltip__body {
    max-width: 15rem;
  }
`;
