import React, { useContext, useEffect, useState } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import SSRIcon from '@ingka/ssr-icon';
import checkmarkIcon from '@ingka/ssr-icon/paths/checkmark-circle';
import incorrectIcon from '@ingka/ssr-icon/paths/incorrect';
import informationIcon from '@ingka/ssr-icon/paths/information';
import informationCircleIcon from '@ingka/ssr-icon/paths/information-circle';
import warningIcon from '@ingka/ssr-icon/paths/warning-triangle';
import { useFetchRules } from 'features/Rules/rules.service';
import { FlexContainer } from 'components/FlexContainer';
import { RuleType } from 'features/Rules';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import * as Styled from '../styles';
import { useLanguageSelection } from '../../../hooks/contexts/LanguageContext';
import { NoData } from '../../../components/NoData';

type DashboardRedirectCardProps = {
  activeTab: string;
};

const filterAndCountRules = (rules: RuleType[], targetStatus: number): number => {
  return rules.filter((obj) => obj.targetStatus === targetStatus).length;
};

const tooltips = {
  200: '200: Everything is good! The website or webpage you’re trying to access is available and working properly.',
  301: '301: The website or webpage you’re trying to access has moved to a new location permanently. It’s like if your friend moved to a new house and gave you their new address.',
  404: '404: Uh-oh, the website or webpage you’re trying to access can’t be found. It’s like going to a store and not being able to find what you’re looking for.',
  totalRules: 'This is the total amount of rules that exist within your current country/language',
};

export const StatusOverviewRedirectCard: React.FC<DashboardRedirectCardProps> = ({ activeTab }) => {
  const [rulesData, setRulesData] = useState<RuleType[]>([]);
  const { selectedLanguage } = useLanguageSelection();
  const policy = activeTab === 'shorturl' ? `ikea.${selectedLanguage.substring(0, 2)}` : selectedLanguage;
  const {
    state: { refetchAfter },
  } = useContext(RulesContext);

  const { data, loading } = useFetchRules({
    refetchAfter,
    policy: policy,
    pageLimit: 0,
    page: 1,
  });

  useEffect(() => {
    if (data?.rules) {
      setRulesData(data.rules);
    }
  }, [data]);

  const replaceAllTitle = (title: string) => {
    return title.replaceAll(' ', '-').toLowerCase();
  };

  const cards = [
    {
      type: 'negative' as const,
      icon: incorrectIcon,
      text: filterAndCountRules(rulesData, 404),
      title: 'Errors 404',
      tooltip: tooltips[404],
    },
    {
      type: 'cautious' as const,
      icon: warningIcon,
      text: filterAndCountRules(rulesData, 301),
      title: 'Warnings 301',
      tooltip: tooltips[301],
    },
    {
      type: 'positive' as const,
      icon: checkmarkIcon,
      text: filterAndCountRules(rulesData, 200),
      title: 'Target status 200',
      tooltip: tooltips[200],
    },
    {
      type: 'informative' as const,
      icon: informationCircleIcon,
      text: rulesData.length,
      title: 'Total rules',
      tooltip: tooltips.totalRules,
    },
  ];

  if (loading) {
    return (
      <FlexContainer justify="center" p={3}>
        <Loading text={'Please wait...'}>
          <LoadingBall />
        </Loading>
      </FlexContainer>
    );
  }

  if (selectedLanguage === '*') {
    return <NoData heading={"You haven't selected a language yet"} text={"Select a language, and we'll look again"} />;
  }

  if (!rulesData.length && !loading) {
    return <NoData heading={`There is no data for ${activeTab}`} />;
  }

  return (
    <FlexContainer direction={'row'} gap={0.4}>
      {cards.map((card, index) => (
        <Styled.StatusOverviewBox role={`overview-redirect-card-${replaceAllTitle(card.title)}`} key={index}>
          <Styled.DashboardBoxHeader type={card.type}>
            <SSRIcon paths={card.icon} height="200" />
            <span data-testid={`overview-redirect-card-text-${replaceAllTitle(card.title)}`}>{card.text}</span>
          </Styled.DashboardBoxHeader>

          <Styled.StatusOverviewText>
            {card.title} <Styled.TooltipWithLessWidth tooltipText={card.tooltip} ssrIcon={informationIcon} position={'bottom'} />
          </Styled.StatusOverviewText>
        </Styled.StatusOverviewBox>
      ))}
    </FlexContainer>
  );
};
