import React, { FC } from 'react';
import Text from '@ingka/text';
import { FlexContainer } from '../FlexContainer';
import * as Styled from './styles';
import { space200 } from '@ingka/variables';

interface NoDataProps {
  heading: string;
  text?: string;
  id?: string;
  imageFlexPosition?: 'row' | 'column';
  contentAlign?: 'flex-start' | 'center';
  textAlign?: 'center' | 'left';
  contentMaxWidth?: string;
}

export const NoData: FC<NoDataProps> = ({ id, imageFlexPosition, heading, text, contentAlign = 'flex-start', textAlign, contentMaxWidth }) => {
  return (
    <Styled.NoDataWrapper data-testid={id} justify={'center'} p={space200}>
      <FlexContainer gap={1} align={'center'} direction={imageFlexPosition} justify={'center'}>
        <Styled.NoDataContent direction={'column'} align={contentAlign} textAlign={textAlign} maxWidth={contentMaxWidth}>
          <Text headingSize={'s'}>{heading}</Text>
          {text && <Styled.NoDataTitle bodySize={'m'}>{text}</Styled.NoDataTitle>}
        </Styled.NoDataContent>
        <Styled.NoDataImage src={'/empty-state.svg'} alt={'empty state image'} />
      </FlexContainer>
    </Styled.NoDataWrapper>
  );
};
