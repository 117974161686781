import Loading, { LoadingBall, LoadingBallSize } from '@ingka/loading';
import * as Styled from './styles';
import { FC } from 'react';

interface LoaderParams {
  minHeight?: string;
  loadingBallSize?: LoadingBallSize;
}

export const Loader: FC<LoaderParams> = ({ minHeight, loadingBallSize }) => (
  <Styled.LoaderContainer justify={'center'} align={'center'} minHeight={minHeight}>
    <Loading text={'Please wait...'}>
      <LoadingBall size={loadingBallSize} />
    </Loading>
  </Styled.LoaderContainer>
);
