import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

type SortContextType = {
  sort: SortContextInputs;
  setSort: Dispatch<SetStateAction<SortContextInputs>>;
};

const SortContext = createContext<SortContextType | undefined>(undefined);

export type SortDirection = 'asc' | 'desc';
export type SortFieldRule = 'dateModified' | 'name' | 'invocations' | 'dateStart' | 'dateEnd' | 'redirectType' | 'targetStatus' | 'userModifiedBy';

export type SortContextInputs = {
  field: SortFieldRule | null;
  direction: SortDirection | null;
};

const DEFAULT_SORT = {
  field: null,
  direction: null,
};

const SortProvider: FC<{
  children: ReactNode;
  injectedSort?: SortContextInputs;
}> = ({ children, injectedSort }) => {
  const [sort, setSort] = useState<SortContextInputs>(injectedSort || DEFAULT_SORT);
  return <SortContext value={{ sort, setSort }}>{children}</SortContext>;
};

const useSort = () => {
  const context = useContext(SortContext);
  if (context === undefined) {
    throw new Error('useSort must be used within a SortProvider');
  }
  return context;
};

export { SortProvider, useSort };
