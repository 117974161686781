import React, { FC, ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as Styled from './styles';
import handleBar from '@ingka/ssr-icon/paths/handle';
import Checkbox from '@ingka/checkbox';
import { useTable } from '..';

export type TableRowProps = {
  children: ReactNode;
  onCheckboxClick?: () => void;
  onClick?: () => void;
  clickable?: boolean;
  isSelected?: boolean;
  isDraggable?: boolean;
  id?: string;
  hideActions?: boolean;
  dataTestId?: string;
};

export const TableRow: FC<TableRowProps> = ({
  onCheckboxClick,
  isSelected,
  isDraggable,
  id,
  hideActions,
  children,
  onClick,
  clickable,
  dataTestId,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id ?? '' });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { type } = useTable();

  return (
    <Styled.CollapsedTableRow ref={setNodeRef} style={style} data-testid={dataTestId} onClick={onClick} clickable={clickable}>
      {isDraggable && (
        <td {...attributes} {...listeners}>
          <Styled.PillButton iconOnly size={'small'} ssrIcon={handleBar} />
        </td>
      )}
      {!hideActions && id && onCheckboxClick && (
        <td>
          <Checkbox id={id} name="selectRowCheckbox" data-testid={`${type}-checkbox`} checked={isSelected} onChange={onCheckboxClick} value={id} />
        </td>
      )}
      {children}
    </Styled.CollapsedTableRow>
  );
};
