import styled from '@emotion/styled';

export const AlignedCell = styled.td<{ textAlign?: string }>`
  text-align: ${({ textAlign }) => textAlign || 'left'} !important;
  max-height: 80px;

  & > span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`;
