import { Column as RuleTableColumn } from 'types';
import { RuleType, User } from '../index';
import { TableCell } from 'components/Table/TableCell';
import { formatDate } from 'utils/dates';
import Text from '@ingka/text';
import React, { FC, Fragment } from 'react';
import { StatusIcon } from '../StatusIcon';

export const RuleTableCells: FC<{
  columns: RuleTableColumn[];
  rule: RuleType;
  invocationsShortend: number | string;
  isGlobal: boolean;
}> = ({ columns, rule, invocationsShortend, isGlobal }) => {
  return (
    <>
      {columns.map((column: RuleTableColumn) => {
        const cellValue = rule[column.field as keyof RuleType];
        switch (column.field) {
          case 'matchURL':
          case 'targetURL':
          case 'name':
            return <TableCell text={(cellValue?.toString() as string) || ''} key={column.field} />;
          case 'redirectType':
            return <TableCell text={(cellValue?.toString() as string) || ''} key={column.field} textAlign={'right'} />;
          case 'dateStart':
          case 'dateEnd':
          case 'dateCreated':
          case 'dateModified':
            const formatedDate = cellValue ? formatDate(cellValue as string) : column.field === 'dateEnd' ? 'None' : '';
            return <TableCell text={formatedDate} key={column.field} textAlign={cellValue ? 'right' : 'left'} />;
          case 'userModifiedBy':
          case 'userCreatedBy':
            return <TableCell text={(cellValue as User)?.email} key={column.field} />;
          case 'invocations':
            return <TableCell text={invocationsShortend?.toString()} textAlign={'right'} key={column.field} />;
          case 'status':
            return (
              <TableCell key={column.field} textAlign={'right'}>
                <Text tagName="span" bodySize="s">
                  <a href={cellValue as string} target="_blank" rel="noreferrer">
                    {cellValue as string}
                  </a>
                </Text>
              </TableCell>
            );
          case 'targetStatus':
            return (
              <Fragment key={column.field}>
                {!isGlobal && (
                  <TableCell>
                    <StatusIcon status={cellValue as number} startDate={rule.dateStart} />
                  </TableCell>
                )}
              </Fragment>
            );
          default:
            return null;
        }
      })}
    </>
  );
};
