import React from 'react';
import Breadcrumb from '../Breadcrumb';
import * as Styled from './styles';

interface ContainerProps {
  children: React.ReactNode;
  page?: string;
  padding?: string;
}

const ContainerComp: React.FC<ContainerProps> = (props) => (
  <Styled.ContainerWrapper padding={props.padding}>
    {props.page && <Breadcrumb page={props.page} />}
    {props.children}
  </Styled.ContainerWrapper>
);

export default ContainerComp;
