import styled from '@emotion/styled';
import Pill from '@ingka/pill';

interface CollapsedTableRowProps {
  clickable?: boolean;
}

export const CollapsedTableRow = styled.tr<CollapsedTableRowProps>`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'normal')};
  width: fit-content;

  td:has(button) {
    min-width: 7rem;
  }
`;

export const PillButton = styled(Pill)`
  background-color: transparent;
`;
