import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ApolloClient, ApolloProvider, concat } from '@apollo/client';
import { environment } from 'environment';
import { sendPageLoadEvent } from 'opt-util/episod';

import { AnnouncementBanner } from 'features/admin/AnnouncementForm/AnnouncementBanner';
import ErrorBoundary from 'components/ErrorBoundary';
import { FeedbackSurvey } from 'features/seo/Survey';
import { Header } from 'features/Header';
import { SideNav } from 'features/Header/SideNav';
import { Provider as MetaDataProvider } from 'hooks/contexts/MetaDataContext';
import { Provider as RulesContext } from 'hooks/contexts/RulesContext';
import { Provider as SideNavProvider } from 'hooks/contexts/SideNavContext';
import { Provider as StatusMessageContext } from 'hooks/contexts/StatusMessageContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { Provider as FeedbackProvider } from 'hooks/contexts/FeedbackContext';
import { FilterProvider } from 'hooks/contexts/FilterContext';
import { Context as ErrorMessageContext, Provider as ErrorMessageProvider } from 'hooks/contexts/ErrorMessageContext';
import { useFeatureContext } from 'hooks/contexts/FeatureContext';
import { useCountry } from 'hooks/useCountry';
import { Elm } from 'types/React';
import { UploadRulesDiff } from '../rules/UploadRulesDiff/UploadRulesDiff';
import { DataViewPage } from '../seo/DataViewPage';
import UploadCountryPage from '../seo/UploadCountryPage';
import { UploadPage } from '../seo/UploadPage';
import { UploadResultPage } from '../seo/UploadResultPage';
import { SitemapsPage } from '../sitemaps/SitemapsPage';
import { SitemapStatsDetailView } from '../sitemaps/SitemapStatsDetailView';
import { AdminPage } from '../AdminPage';
import { BookmarkPage } from '../BookmarkPage';
import { CountrySelectPage } from '../CountrySelectPage';
import { Dashboard } from '../Dashboard';
import { GraphsPage } from '../GraphsPage';
import { MetaData } from '../Metadata';
import { RedirectsPage } from '../RedirectsPage';
import { RulesPage } from '../rules/RulesPage';
import { cache, createAuthMiddleware, createLogoutLink, httpLink } from '../../api/graphql';
import { SystemStatusMessage } from 'components/SystemStatusMessage';
import { NotFoundPage } from '../NotFoundPage';
import { LanguageProvider } from '../../hooks/contexts/LanguageContext';
import * as Styled from './styles';
import { ToastProvider } from '../../hooks/contexts/ToastContext';
import { Toast } from '../../components/Toast';
import { checkApiRequest } from '../../api/restApiChecker';
import { SortProvider } from '../../hooks/contexts/SortContext';

export const PageWrapper: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
  const { state } = useContext(ErrorMessageContext);
  const { pathname } = useLocation();

  useEffect(() => {
    sendPageLoadEvent(pathname);
  }, []);

  return (
    <div className={'page'}>
      <StatusMessageContext>
        <RulesContext>
          <MetaDataProvider>
            <FilterProvider>
              <SortProvider>
                <ToastProvider>
                  <AnnouncementBanner />
                  <Toast />
                  <ErrorBoundary>
                    <Header />
                  </ErrorBoundary>
                  <ErrorBoundary hasContextError={state.hasError}>
                    <Styled.PageContainer>
                      <SideNav />
                      <Styled.PageContent>
                        <SystemStatusMessage />
                        {props.children}
                      </Styled.PageContent>
                    </Styled.PageContainer>
                  </ErrorBoundary>
                </ToastProvider>
              </SortProvider>
            </FilterProvider>
          </MetaDataProvider>
        </RulesContext>
        <FeedbackSurvey />
      </StatusMessageContext>
    </div>
  );
};
const wrapped =
  <T extends {}>(Component: React.ComponentType<T>) =>
  (props: T): Elm<T> => (
    <PageWrapper>
      <Component {...props} />
    </PageWrapper>
  );

const wrappedWithLanguage =
  <T extends {}>(Component: React.ComponentType<T>) =>
  (props: T): Elm<T> => (
    <PageWrapper>
      <LanguageProvider>
        <Component {...props} />
      </LanguageProvider>
    </PageWrapper>
  );

export const AuthenticatedRoutes: React.FC = () => (
  <>
    <Switch>
      <Route path={'/'} component={wrappedWithLanguage(Dashboard)} exact />
      <Route path={'/dashboard'} component={wrappedWithLanguage(Dashboard)} exact />
      <Route path={'/admin/:tool'} component={wrapped(AdminPage)} />
      <Route path={'/sitemaps'} exact component={wrapped(SitemapsPage)} />
      <Route path={'/sitemaps/stats/:language-:country'} component={wrapped(SitemapStatsDetailView)} />
      <Route path={'/graphs'} component={wrapped(GraphsPage)} />
      <Route path={'/redirects/:country'} component={wrappedWithLanguage(RedirectsPage)} exact />
      <Route path={'/redirects/:country/:policy'} component={wrapped(RulesPage)} exact />
      <Route path={'/redirects/:country/:policy/upload-diff/:id'} component={wrapped(UploadRulesDiff)} exact />
      <Route path={'/metadata'} component={wrappedWithLanguage(MetaData)} exact />
      <Route path={'/metadata/upload/:country/:language/:type'} component={wrapped(UploadCountryPage)} />
      <Route path={'/metadata/upload'} component={wrapped(UploadPage)} />
      <Route path={'/metadata/uploadResult/:country/:language/:type'} component={wrapped(UploadResultPage)} />
      <Route path={'/metadata/:country/:language/:type'} component={wrapped(DataViewPage)} />
      <Route path={'/bookmarklet'} component={wrapped(BookmarkPage)} />
      <Redirect from="/login" to="/" />
      <Route path={'*'} component={wrapped(NotFoundPage)} />
    </Switch>
  </>
);

export const Routes: React.FC = () => {
  const originalPath = sessionStorage.getItem('originalPath');
  const [country] = useCountry();
  const history = useHistory();
  const {
    state: { isLoggedIn },
    refreshToken,
    logout,
  } = useContext(LoginContext);
  const { updateFeatureList } = useFeatureContext();

  const updatePath = () => {
    if (originalPath) {
      sessionStorage.removeItem('originalPath');
      if (window.location.pathname !== originalPath) {
        history?.push(originalPath);
      }
    }
  };

  const getFeatures = async () => {
    const token = await refreshToken();
    updateFeatureList(token);
    checkApiRequest(token).catch(console.error);
  };

  useEffect(() => {
    updatePath();
    getFeatures();
  }, []);

  const apolloClient = new ApolloClient({
    link: createLogoutLink(logout).concat(concat(createAuthMiddleware(refreshToken), httpLink)),
    cache,
  });

  if (isLoggedIn) {
    if (!country) {
      return (
        <ApolloProvider client={apolloClient}>
          <Router>
            <CountrySelectPage />
          </Router>
        </ApolloProvider>
      );
    }
    return (
      <ApolloProvider client={apolloClient}>
        <Router>
          <ErrorMessageProvider>
            <FeedbackProvider>
              <SideNavProvider>
                <AuthenticatedRoutes />
              </SideNavProvider>
            </FeedbackProvider>
          </ErrorMessageProvider>
        </Router>
      </ApolloProvider>
    );
  } else {
    if (!originalPath) {
      sessionStorage.setItem('originalPath', window.location.pathname);
    }
    window.location.replace(environment.serviceUrl + environment.backendLoginPath);
    return null;
  }
};
