import React from 'react';
import { TableBody, TableHeader } from '@ingka/table';
import { RuleTypeDiff } from 'pages/rules/UploadRulesDiff/UploadRulesDiff';
import { useComponentOffsetTop } from 'hooks/useComponentOffsetTop';
import { RuleDiff } from '../RuleDiff';
import * as Styled from '../styles';
import { tooltipText } from '../tooltip';
import { Tooltip } from 'components/Tooltip';

export const RulesDiff: React.FC<{ rules: RuleTypeDiff[] }> = ({ rules }) => {
  const { componentRef: tableRef, componentOffsetTop: offsetTop } = useComponentOffsetTop();

  const columnHeaders = ['Redirect from', 'Redirect to', 'name', 'Start date', 'End date', 'Type', 'Target status'];
  return (
    <div ref={tableRef}>
      <Styled.Table offsettop={offsetTop} fullWidth>
        <TableHeader sticky>
          <tr>
            {columnHeaders.map((columnHeader, index) => (
              <th key={index}>
                <Styled.TableColumnHeading>
                  {columnHeader} {columnHeader === 'Target status' && <Tooltip>{tooltipText['targetStatus']}</Tooltip>}
                </Styled.TableColumnHeading>
              </th>
            ))}
          </tr>
        </TableHeader>
        <TableBody striped>
          {rules.map((rule, index) => (
            <RuleDiff key={index} rule={rule} />
          ))}
        </TableBody>
      </Styled.Table>
    </div>
  );
};
