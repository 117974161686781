import React, { FC, ReactNode } from 'react';
import * as Styled from './styles';

export type TableThumbnailRowProps = {
  children: ReactNode;
  dataTestId?: string;
};

export const TableThumbnailRow: FC<TableThumbnailRowProps> = ({ children, dataTestId }) => {
  return <Styled.TableThumbnailRow data-testid={dataTestId}>{children}</Styled.TableThumbnailRow>;
};
