import * as React from 'react';
import { ChangeEvent, JSX, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading, { LoadingBall } from '@ingka/loading';
import { History } from 'history';
import { UploadResult } from 'features/admin/Keywords';
import { MetadataProps } from 'types';
import { ErrorMessage } from 'components/styles';
import { UploadSuccess } from '../styles';

interface FileUploadFormDispatch {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  postFile: (file: File, history?: History, options?: MetadataProps) => any;
  options?: MetadataProps;
  customMessage?: JSX.Element;
  customResult?: (response: UploadResult) => JSX.Element;
}

export const FileUploadForm: React.FC<FileUploadFormDispatch> = ({ postFile, options, customMessage, customResult }) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState<UploadResult>();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSuccess(false);
      setIsLoading(true);
      setError('');

      try {
        const response = await postFile(event.target.files[0], history, options);
        const result = await response.json();
        if (response.status === 200) {
          setSuccess(true);
          if (result) {
            setResponse(result);
          }
        } else {
          setError(result.error);
        }
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {customMessage}
      <input data-testid={'file-upload'} type={'file'} onChange={handleUploadFile} name={'file'} />
      {isLoading && (
        <Loading text={'Please wait...'}>
          <LoadingBall />
        </Loading>
      )}
      {error && (
        <>
          <hr />
          <h4>Error</h4>
          <ErrorMessage>{error}</ErrorMessage>
          <hr />
        </>
      )}
      {success && (
        <>
          <hr />
          <UploadSuccess>File successfully uploaded</UploadSuccess>
          {response && customResult && customResult(response)}
          <hr />
        </>
      )}
    </>
  );
};
